.SwipperGaleria {
    max-width: 48rem;
    height: 100%;

    .swiper-slide {
        text-align: center;
        font-size: 18px;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;

        .footer {
            width: 100%;
            h3 {
                padding-left: 0.4rem;
                padding-top: 0.2rem;
                text-align: start;
                font-size: 1.2rem;
                font-weight: 600;
                color: var(--gold-color);
            }
            p {
                text-align: start;
                font-size: 1rem;
                color: var(--gray-500);
            }
        }
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.2rem;
        margin-bottom: 1rem;
    }

    .swiper-button-prev {
        left: initial;
        right: 2rem;
    }

    .swiper-button-next,
    .swiper-button-prev {
        bottom: -12px;
        top: initial;
        &:after {
            font-family: swiper-icons;
            font-size: 16px;
            font-weight: 600;
            color: var(--gold-color);
            text-transform: none !important;
            letter-spacing: 0;
            text-transform: none;
            font-variant: initial;
            line-height: 1;
        }
    }
}
