.SwipperTestimonials {
  max-width: 41rem;
  // height: 20rem;
  @media (max-width: 1399.98px) {
    // height: 40rem;
  }
  .swiper-wrapper {
    align-items: center;
  }
  .swiper-slide {
    border-radius: 0.4rem;
    background: #fff;
    width: 100%;
    height: 40rem;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    .SwiperContent {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 4rem;
      @media (max-width: 767.98px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      h2 {
        color: #c2c2c2;
        text-align: center;
      }
      .img-photo {
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
        border: 2px solid var(--gold-color);
      }
      div {
        h3 {
          color: var(--brow-color);
          font-size: 1.5rem;
        }
        p {
          text-align: justify;
          filter: brightness(0.8);
          span {
            color: var(--gold-color);
            font-weight: 1.4rem;
          }
        }
        .img-signature {
          margin-top: 1rem;
          height: 2rem;
        }
        @media (max-width: 575.98px) {
          h3,
          p,
          .img-signature {
            text-align: center;
          }
        }
      }
    }
  }

  // .swiper-slide {
  //   img {
  //     display: block;
  //     width: 100%;
  //     height: 100%;
  //     object-fit: cover;
  //   }
  // }

  .swiper-button-next,
  .swiper-button-prev {
    &::before {
      content: "";
      width: 2.5rem;
      height: 2.5rem;
      background-color: rgb(255, 255, 255);
      box-shadow: 0px 1px 0px 2px rgba(0, 0, 0, 0.1);
      z-index: -1;
      border-radius: 50%;
      position: absolute;
    }
    &:after {
      font-family: swiper-icons;
      font-size: 10px;
      font-weight: 600;
      color: #cb8f55;
      text-transform: none !important;
      letter-spacing: 0;
      text-transform: none;
      font-variant: initial;
      line-height: 1;
    }
  }
}
